import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { ErrorComponent } from './shared/components/shared-error/error.component';
import { AuthGuard } from './core/guards/auth.guard';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { CustomLoaderComponent } from './shared/components/custom-loader/custom-loader.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { ConfirmationService, MessageService } from 'primeng/api';
import { LinkExpiredComponent } from './public/components/public-link-expired/linkExpired.component';
import { InitService } from './core/services/init.service';
import { NotificationService } from './core/services/notification.service';
import { PodacastComponent } from './public/components/podcast/podcast.component';
const initApp = (appInitService: InitService) => (): Promise<any> => appInitService.init();

const appRoutes: Routes = [
  { path: '', loadChildren: './public/public.module#PublicModule' },
  { path: 'back-office', loadChildren: './back-office/back-office.module#BackOfficeModule', canActivate: [AuthGuard] },
  { path : 'podcast', component: PodacastComponent},
  { path: 'error', component: ErrorComponent },
  { path: 'linkExpired', component: LinkExpiredComponent },
  { path: '**', redirectTo: 'error' }
];

const IMPORTS = [
  BrowserModule,
  BrowserAnimationsModule,
  RouterModule.forRoot(appRoutes),
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient]
    }
  }),
  SharedModule
];
const DECLARATIONS = [AppComponent];
const BOOTSTRAP = [AppComponent];
const ENTRY_COMPONENTS = [CustomLoaderComponent];

@NgModule({
  imports: [...IMPORTS],
  declarations: [...DECLARATIONS],
  bootstrap: [...BOOTSTRAP],
  entryComponents: [...ENTRY_COMPONENTS],
  providers: [
    ConfirmationService, 
    MessageService, 
    InitService,
    NotificationService,
    { provide: APP_INITIALIZER, useFactory: initApp, multi: true, deps: [InitService] }
  ]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
