import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  SimpleChanges
} from '@angular/core';
import { MediaApi } from '../../../core/apis/media.api';
import { ContentManagementApi } from '../../../core/apis/content-management-api.service';
import { SlugService } from '../../../core/services/slug.service';
import { AppDataService } from '../../../core/services/app-data.service';
const env = require('src/environments/environment');
import JSONEditor from '@json-editor/json-editor';
import { MenuService } from 'src/app/core/services/menu.service';
import { CommonApi } from 'src/app/core/apis/common.api';

declare var window: any;
declare var $extend: any;

import { Italian } from 'flatpickr/dist/l10n/it.js';
import * as moment from 'moment';
moment.locale('it');

@Component({
  selector: 'json-editor',
  templateUrl: './json-editor.component.html',
  styleUrls: ['./json-editor.component.scss']
})
export class JsonEditorComponent implements OnInit, OnChanges, OnDestroy {
  @Input() jsonValues = {};
  @Input() jsonSchema: any = {};
  @Input() selectedLanguage: any;
  @Input() contentType: string;
  @Input() contentPending: boolean;
  @Input() contentApproved: boolean;
  @Input() editorAlwaysEnabled: boolean;
  @Input() canEditAfterApproval: boolean;

  @Output() valuesToSave = new EventEmitter();
  @Output() addImage = new EventEmitter();
  @Output() addPdf = new EventEmitter();
  @Output() addIcs = new EventEmitter();
  @Output() addVideo = new EventEmitter();
  @Output() editorObj = new EventEmitter();

  @Output() templateChange = new EventEmitter();

  editorId: string = 'cmsEditor';

  tempValues: any;

  editor: any;
  imageFieldSelected: any;
  apiKey = '?apikey=' + env.environment.apiKey;

  contentsByMenu = { enum: [], labels: [] };
  pressMailTemplates = {
    enum: [],
    labels: [],
    colorSet: [],
    fontSet: [],
    linkSet: [],
    templatesContent: []
  };

  jsonEditorElement: HTMLElement;

  protected options = {
    ajax: true,
    compact: true,
    array_controls_top: false,
    disable_array_add: false,
    disable_array_delete: false,
    disable_array_reorder: false,
    disable_array_delete_last_row: true,
    disable_array_delete_all_rows: true,
    disable_collapse: true,
    disable_edit_json: true,
    disable_properties: true,
    display_required_only: false,
    expand_height: true,
    form_name_root: 'root',
    iconlib: 'fontawesome4',
    keep_oneof_values: false,
    no_additional_properties: true,
    object_layout: 'normal',
    prompt_before_delete: true,
    refs: {},
    required_by_default: true,
    schema: {},
    show_errors: 'always',
    startval: null,
    template: 'swig',
    theme: 'bootstrap3Custom'
  };

  constructor(
    protected mediaApi: MediaApi,
    protected contentManagementApi: ContentManagementApi,
    protected appData: AppDataService,
    protected cdr: ChangeDetectorRef,
    protected slugService: SlugService,
    private menuService: MenuService,
    protected commonApi: CommonApi
  ) {}

  ngOnInit() {
    this.loadDataBeforeJsonEditorInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.selectedLanguage &&
      changes.selectedLanguage.previousValue &&
      changes.selectedLanguage.previousValue !== changes.selectedLanguage.currentValue
    ) {
      this.clearEditors();
      this.jsonValues = this.tempValues;
      this.loadDataBeforeJsonEditorInit();
    }
    if (
      changes.jsonSchema &&
      changes.jsonSchema.previousValue &&
      changes.jsonSchema.previousValue.$ref &&
      changes.jsonSchema.currentValue.$ref &&
      changes.jsonSchema.previousValue.$ref !== changes.jsonSchema.currentValue.$ref
    ) {
      this.clearEditors();
      this.jsonValues = this.tempValues;
      this.loadDataBeforeJsonEditorInit();
    }
  }

  async loadDataBeforeJsonEditorInit() {
    if (Object.keys(this.jsonSchema).length > 0) {
      const user = this.appData.getAppData('currentUser');
      switch (this.jsonSchema.$ref) {
        case '../../../../../assets/schemas/mail/mail-templates.json':
          const menuContents = await this.menuService.getSectionsByEndpoint('press-content');
          this.contentsByMenu.enum = menuContents.map(menuItem => menuItem.data.type);
          this.contentsByMenu.labels = menuContents.map(menuItem => menuItem.label);
          break;
        case '../../../../../assets/schemas/press/press-content.json':
        case '../../../../../assets/schemas/press/press-reviews-ds.json':
          const [_, typeKey] = this.appData.getAppData('routerData');
          const res = await this.contentManagementApi
            .getListContent('mail-templates/published', [`locale=${this.selectedLanguage}`])
            .toPromise();
          this.pressMailTemplates.templatesContent = res;
          const role = user.roles[0].name;
          const resFilter = res.filter(curr => {
            const listRoleName = curr.content.templateRoles ? curr.content.templateRoles.map(n => n.role) : [];
            return curr.content.templateType === typeKey && listRoleName.includes(role);
          });
          this.pressMailTemplates.enum = resFilter.map(template => {
            return template.slug;
          });
          this.pressMailTemplates.labels = resFilter.map(template => {
            return template.title;
          });
          this.pressMailTemplates.colorSet = resFilter.reduce(
            // @ts-ignore
            (acc, { slug, content: { general_settings: { colors } = {} } }) => ({
              ...acc,
              [slug]: colors ? colors.map(({ color }) => color) : []
            }),
            {}
          );
          this.pressMailTemplates.fontSet = resFilter.reduce(
            // @ts-ignore
            (acc, { slug, content: { general_settings: { fonts } = {} } }) => ({
              ...acc,
              [slug]: fonts ? fonts.map(({ font }) => font) : []
            }),
            {}
          );

          this.pressMailTemplates.linkSet = resFilter.reduce(
            (acc, { slug, content: { link_press_complete } }) => ({
              ...acc,
              [slug]: link_press_complete && link_press_complete.links ? link_press_complete.links : []
            }),
            {}
          );
          break;
        default:
          break;
      }
      this.jsonEditorInit();
    }
  }

  clearEditors() {
    this.cdr.detectChanges();
    const element = document.getElementById(this.editorId);
    while (element.firstChild) {
      element.removeChild(element.firstChild);
    }
  }

  jsonEditorInit() {
    const values = this.jsonValues;
    const options = this.options;
    options.schema = this.jsonSchema;
    const mailTemplates = this.pressMailTemplates;

    const self = this;

    JSONEditor.defaults.custom_validators.push(function(schema, value, path) {
      const errors = [];
      if (schema.format === 'validUrl') {
        if (
          value &&
          !/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/.test(value)
        ) {
          // Errors must be an object with `path`, `property`, and `message`
          errors.push({
            path: path,
            property: 'format',
            message: 'Formato url non valido'
          });
        }
      }
      return errors;
    });

    // ---> Summernote text editor
    JSONEditor.defaults.editors.wysiwyg = JSONEditor.defaults.editors.string.extend({
      disable: function(args) {
        this._super(args);
        if (this.options.wysiwyg === 'summernote') {
          window.jQuery(this.input).summernote('disable');
        }
      },
      setValue: function(value, initial, from_template) {
        var self = this;

        if (this.template && !from_template) {
          return;
        }

        if (value === null || typeof value === 'undefined') value = '';
        else if (typeof value === 'object') value = JSON.stringify(value);
        else if (typeof value !== 'string') value = '' + value;

        if (value === this.serialized) return;

        // Sanitize value before setting it
        var sanitized = this.sanitize(value);

        if (this.input.value === sanitized) {
          return;
        }

        this.input.value = sanitized;

        // If using SCEditor, update the WYSIWYG
        if (this.sceditor_instance) {
          this.sceditor_instance.val(sanitized);
        } else if (this.summernote_instance) {
          this.summernote_instance.summernote('code', sanitized);
        } else if (this.SimpleMDE) {
          this.SimpleMDE.value(sanitized);
        } else if (this.ace_editor) {
          this.ace_editor.setValue(sanitized);
        }

        var changed = from_template || this.getValue() !== value;

        this.refreshValue();

        if (initial) this.is_dirty = false;
        else if (this.jsoneditor.options.show_errors === 'change') this.is_dirty = true;

        if (this.adjust_height) this.adjust_height(this.input);

        // Bubble this setValue to parents if the value changed
        this.onChange(changed);
      },
      afterInputReady: function() {
        var textEditor = this,
          options;

        // Code editor
        if (this.source_code) {
          // WYSIWYG html and bbcode editor
          if (
            this.options.wysiwyg &&
            ['html', 'bbcode'].indexOf(this.input_type) >= 0 &&
            window.jQuery &&
            window.jQuery.fn &&
            window.jQuery.fn.sceditor
          ) {
            options = $extend(
              {},
              {
                plugins: textEditor.input_type === 'html' ? 'xhtml' : 'bbcode',
                emoticonsEnabled: false,
                width: '100%',
                height: 300
              },
              JSONEditor.plugins.sceditor,
              textEditor.options.sceditor_options || {}
            );

            window.jQuery(textEditor.input).sceditor(options);

            textEditor.sceditor_instance = window.jQuery(textEditor.input).sceditor('instance');

            textEditor.sceditor_instance.blur(function() {
              // Get editor's value
              var val = window.jQuery('<div>' + textEditor.sceditor_instance.val() + '</div>');
              // Remove sceditor spans/divs
              window.jQuery('#sceditor-start-marker,#sceditor-end-marker,.sceditor-nlf', val).remove();
              // Set the value and update
              textEditor.input.value = val.html();
              textEditor.value = textEditor.input.value;
              textEditor.is_dirty = true;
              textEditor.onChange(true);
            });
          } else if (
            this.options.wysiwyg === 'summernote' &&
            (this.input_type === 'html' || this.input_type === 'htmlCode') &&
            window.jQuery &&
            window.jQuery.fn &&
            window.jQuery.fn.summernote
          ) {
            const input = window.jQuery(textEditor.input);
            textEditor.summernote_instance = input.summernote({
              width: '75%',
              height: 100,
              disableDragAndDrop: true,
              followingToolbar: false,
              styleTags: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
              fontNames: [
                'Arial',
                'Arial Black',
                'Calibri',
                'Courier New',
                'DIN Regular',
                'Helvetica Neue',
                'Helvetica',
                'Impact',
                'Lucida Grande',
                'Tahoma',
                'Times New Roman',
                'Verdana',
                'Druk',
                'Fraktionmono',
                'TradeGothicLTStd'
              ],
              fontNamesIgnoreCheck: ['DIN Regular', 'Calibri', 'Druk', 'Fraktionmono', 'TradeGothicLTStd'],
              popover: {
                image: [
                  ['image', ['resizeFull', 'resizeHalf', 'resizeQuarter', 'resizeNone']],
                  ['float', ['floatLeft', 'floatRight', 'floatNone']],
                  ['remove', ['removeMedia']]
                ],
                link: [],
                air: []
              },
              toolbar: [
                ['style', ['style']],
                ['font', ['bold', 'underline', 'clear', 'italic']],
                ['fontname', ['fontname']],
                ['fontsize', ['fontsize']],
                ['fontsizeunit', ['fontsizeunit']],
                ['para', ['ul', 'ol', 'paragraph', 'height']],
                ['link', ['link']],
                ['color', ['color']],
                ['forecolor', ['forecolor']],
                ['backcolor', ['backcolor']],
                ['superscript', ['superscript']],
                ['subscript', ['subscript']],
                ['misc', ['undo', 'redo']],
                ['table', ['table']],
                ['insert', ['link', 'picture', 'video', 'hr']],
                ['view', ['fullscreen', 'codeview']]
              ],
              callbacks: {
                onBlur: function(e) {
                  // simpler but risky: self.input.value.replace(/b>/g, 'strong>');
                  textEditor.value = textEditor.input.value.replace(/<b>|<\/b>/g, (tag: string) => {
                    return tag.length < 4 ? '<strong>' : '</strong>';
                  });
                  textEditor.is_dirty = true;
                  textEditor.onChange(true);
                }
              }
            });

            let defaultFont;
            const TEMPLATE_PATH = 'root.mail_template';
            const templateEditor = editor.getEditor(TEMPLATE_PATH);
            if (templateEditor) {
              const template = templateEditor.getValue();
              defaultFont = self.pressMailTemplates.fontSet[template] && self.pressMailTemplates.fontSet[template][0];
            }

            input.summernote('fontName', defaultFont || 'DIN Regular');
            input.summernote('fontSize', 14);
            input.next().css({ 'margin-left': '20%', top: '-20px' });
          }
          // SimpleMDE for markdown (if it's loaded)
          else if (this.input_type === 'markdown' && window.SimpleMDE) {
            options = $extend({}, JSONEditor.plugins.SimpleMDE, {
              element: this.input
            });

            this.SimpleMDE = new window.SimpleMDE(options);

            this.SimpleMDE.codemirror.on('change', function() {
              textEditor.value = textEditor.SimpleMDE.value();
              textEditor.is_dirty = true;
              textEditor.onChange(true);
            });
          }
          // ACE editor for everything else
          else if (window.ace) {
            var mode = this.input_type;
            // aliases for c/cpp
            if (mode === 'cpp' || mode === 'c++' || mode === 'c') {
              mode = 'c_cpp';
            }

            this.ace_container = document.createElement('div');
            this.ace_container.style.width = '100%';
            this.ace_container.style.position = 'relative';
            this.ace_container.style.height = '400px';
            this.input.parentNode.insertBefore(this.ace_container, this.input);
            this.input.style.display = 'none';
            this.ace_editor = window.ace.edit(this.ace_container);

            this.ace_editor.setValue(this.getValue());

            // The theme
            if (JSONEditor.plugins.ace.theme) this.ace_editor.setTheme('ace/theme/' + JSONEditor.plugins.ace.theme);
            // The mode
            this.ace_editor.getSession().setMode('ace/mode/' + this.schema.format);

            // Listen for changes
            this.ace_editor.on('change', function() {
              var val = textEditor.ace_editor.getValue();
              textEditor.input.value = val;
              textEditor.refreshValue();
              textEditor.is_dirty = true;
              textEditor.onChange(true);
            });
          }
        }

        textEditor.theme.afterInputReady(textEditor.input);
      },
      destroy: function() {
        // If using SCEditor, destroy the editor instance
        if (this.sceditor_instance) {
          this.sceditor_instance.destroy();
        } else if (this.summernote_instance) {
          this.summernote_instance.summernote('destroy');
        } else if (this.SimpleMDE) {
          this.SimpleMDE.destroy();
        } else if (this.ace_editor) {
          this.ace_editor.destroy();
        }

        this.template = null;
        if (this.input && this.input.parentNode) this.input.parentNode.removeChild(this.input);
        if (this.label && this.label.parentNode) this.label.parentNode.removeChild(this.label);
        if (this.description && this.description.parentNode) this.description.parentNode.removeChild(this.description);

        this._super();
      }
    });
    JSONEditor.defaults.resolvers.unshift(schema => {
      if (schema.type === 'string' && schema.format === 'html') {
        return 'wysiwyg';
      }
    });

    // ---> Default image for header and footer with preview
    JSONEditor.defaults.editors.defaultImage = JSONEditor.defaults.editors.string.extend({
      build: function() {
        this._super();
        const templatePath = 'root.mail_template';
        const mainDiv = document.createElement('div');
        mainDiv.setAttribute('class', 'form-group');
        mainDiv.setAttribute('style', 'display: flex');
        const image = document.createElement('img');
        const imageLabePlaceholder = document.createElement('label');

        image.setAttribute('id', `image-${this.key}`);
        image.style.maxHeight = '200px';
        image.style.maxWidth = '200px';
        image.style.marginLeft = '5px';
        mainDiv.appendChild(image);
        mainDiv.appendChild(imageLabePlaceholder);
        this.container.appendChild(mainDiv);

        const splittedPath = this.path.split('.');
        const childKey = splittedPath[splittedPath.length - 1];
        const parentPath = splittedPath.slice(0, splittedPath.length - 1).join('.');

        editor.watch(templatePath, () => {
          const templateSlug = editor.getEditor(templatePath).getValue();
          const template = mailTemplates.templatesContent.find(mailTemplate => mailTemplate.slug === templateSlug);

          if (template) {
            const imageID = template.content[this.key].id;
            if (imageID) {
              const src = `${env.environment.apiUrl}network-content/medias/resize/${imageID}${self.apiKey}&width=200`;
              image.setAttribute('src', src);
              imageLabePlaceholder.innerHTML = '';
            } else {
              image.removeAttribute('src');
              imageLabePlaceholder.innerHTML = 'No default image present';
              imageLabePlaceholder.setAttribute('style', 'color: red');
            }

            const updateImage = () => {
              const imgDefault = editor.getEditor(parentPath);
              if (imgDefault) {
                const oldImg = imgDefault.getValue();
                if (oldImg[childKey] !== imageID) {
                  imgDefault.setValue({ [childKey]: imageID });
                }
              }
            };

            if (editor.ready) {
              updateImage();
            } else {
              editor.on('ready', updateImage);
            }
          }
        });
      }
    });

    JSONEditor.defaults.resolvers.unshift(schema => {
      if (schema.extension === 'defaultImage') {
        return 'defaultImage';
      }
    });

    // ---> CMS image picker
    JSONEditor.defaults.editors.image = JSONEditor.defaults.editors.string.extend({
      build: function() {
        this._super();
        const imageInput = this;
        this.input.setAttribute('id', 'text-' + imageInput.formname);

        const image = document.createElement('img');
        image.setAttribute('src', '');
        image.setAttribute('id', 'image-' + imageInput.formname);
        image.style.maxHeight = '200px';
        image.style.maxWidth = '200px';
        image.style.marginLeft = '5px';

        const selectButton = document.createElement('button');
        selectButton.innerHTML = 'Select Image';
        selectButton.setAttribute('class', 'btn btn-primary');
        // disable for readonly content type
        if (['pressReviewsDSContent'].includes(self.contentType)) {
          selectButton.setAttribute('disabled', 'true');
        }
        selectButton.style.margin = '0 5px 15px 0';
        selectButton.addEventListener('click', e => {
          e.preventDefault();
          e.stopPropagation();
          self.addImage.emit(imageInput.formname);
        });

        const resetButton = document.createElement('button');
        resetButton.innerHTML = 'Reset Image';
        resetButton.setAttribute('class', 'btn btn-secondary');
        resetButton.setAttribute('disabled', 'true');
        resetButton.style.margin = '0 5px 15px 0';
        resetButton.addEventListener('click', e => {
          e.preventDefault();
          e.stopPropagation();
          const imageField = editor.getEditor(imageInput.path);
          const imageId = editor.getEditor(imageInput.path.replace('.fileName', '.id'));
          if (imageField) {
            imageField.setValue('');
            imageId.setValue('');
            image.setAttribute('src', '');
          }
        });

        editor.watch(imageInput.path, () => {
          if (editor.getEditor(imageInput.path) && editor.getEditor(imageInput.path).value) {
            const imageId = editor.getEditor(imageInput.path.replace('.fileName', '.id')).value;
            if (imageId !== '') {
              const src = `${env.environment.apiUrl}network-content/medias/resize/${imageId}${self.apiKey}&width=200`;
              // self.mediaApi.getImage(imageId).subscribe((res: any) => {
              //   const src = self.baseUrl + res.fileName + '/' + self.apiKey;
              image.setAttribute('src', src);
              // disable for readonly content type
              if (!['pressReviewsDSContent'].includes(self.contentType)) {
                resetButton.removeAttribute('disabled');
              }
              // });
            } else {
              resetButton.setAttribute('disabled', 'true');
            }
          }
        });

        const mainDiv = document.createElement('div');
        mainDiv.setAttribute('class', 'form-group');
        mainDiv.setAttribute('style', 'display: flex');
        mainDiv.appendChild(selectButton);
        mainDiv.appendChild(resetButton);
        mainDiv.appendChild(image);

        this.container.appendChild(mainDiv);
      }
    });
    JSONEditor.defaults.resolvers.unshift(schema => {
      if (schema.type === 'string' && schema.extension === 'cmsImage') {
        return 'image';
      }
    });

    // ---> Custom color picker with preview
    JSONEditor.defaults.editors.customColorPick = JSONEditor.defaults.editors.select.extend({
      build: function() {
        this._super();
        const colorSpan = document.createElement('div');
        colorSpan.setAttribute('class', 'color-indicator');
        this.control.appendChild(colorSpan);
        this.control.setAttribute('class', 'color-pick-row');
        this.colorSpan = colorSpan;
      },
      postBuild: function(e) {
        this._super();
        editor.watch(this.path, () => {
          const editorEntity = editor.getEditor(this.path);
          if (editorEntity) {
            this.colorSpan.setAttribute('style', `background: ${editorEntity.value}`);
          }
        });
      }
    });
    JSONEditor.defaults.resolvers.unshift(schema => {
      if (schema.type === 'string' && schema.extension === 'customColorPick') {
        return 'customColorPick';
      }
    });

    // ---> Dynamic template type in mail templates based on the side menu contents
    JSONEditor.defaults.editors.pressContents = JSONEditor.defaults.editors.selectize.extend({
      preBuild: function() {
        this.schema['enum'] = self.contentsByMenu.enum;
        this.schema['options']['enum_titles'] = self.contentsByMenu.labels;
        this._super();
      }
    });
    JSONEditor.defaults.resolvers.unshift(schema => {
      if (schema.type === 'string' && schema.extension === 'pressContents') {
        return 'pressContents';
      }
    });
    // ---> Dynamic templates based on the current content in the DEM editor
    JSONEditor.defaults.editors.pressMailTemplates = JSONEditor.defaults.editors.selectize.extend({
      preBuild: function() {
        this.schema['enum'] = self.pressMailTemplates.enum;
        this.schema['options']['enum_titles'] = self.pressMailTemplates.labels;
        this._super();
      }
    });
    JSONEditor.defaults.resolvers.unshift(schema => {
      if (schema.type === 'string' && schema.extension === 'pressMailTemplates') {
        return 'pressMailTemplates';
      }
    });

    // ---> Inject in the DEM editor the configuration from the mail template
    JSONEditor.defaults.editors.pressConfig = JSONEditor.defaults.editors.array.extend({
      postBuild: function() {
        this._super();

        const TEMPLATE_PATH = 'root.mail_template';
        const templateEditor = editor.getEditor(TEMPLATE_PATH);

        const setTemplateConfig = () => {
          const template = templateEditor.getValue();
          this.setValue(self.pressMailTemplates[this.key][template]);
        };

        editor.on('ready', setTemplateConfig);
        editor.watch(TEMPLATE_PATH, setTemplateConfig);
      }
    });
    JSONEditor.defaults.resolvers.unshift(schema => {
      if (schema.extension === 'pressConfig') {
        return 'pressConfig';
      }
    });

    // ---> Social section
    JSONEditor.defaults.editors.social = JSONEditor.defaults.editors.object.extend({
      postBuild: function() {
        this._super();

        const TEMPLATE_PATH = 'root.mail_template';
        editor.watch(TEMPLATE_PATH, () => {
          const templateSlug = editor.getEditor(TEMPLATE_PATH).getValue();
          const currentTemplate = self.pressMailTemplates.templatesContent.find(
            mailTemplate => mailTemplate.slug === templateSlug
          );

          const socialsBlock =
            currentTemplate &&
            currentTemplate.content &&
            currentTemplate.content.social_section &&
            currentTemplate.content.social_section.social;
          const currentSocial = socialsBlock && socialsBlock[this.key];

          if (currentSocial) {
            this.container.style.display = currentSocial.visible ? '' : 'none';
          }
        });
      }
    });
    JSONEditor.defaults.resolvers.unshift(schema => {
      if (schema.type === 'object' && schema.extension === 'social') {
        return 'social';
      }
    });

    // ---> Title style settings based on the mail template default settings (override in the editor component)
    JSONEditor.defaults.editors.customTitle = JSONEditor.defaults.editors.object.extend({
      preBuild: function() {
        this._super();
      },
      postBuild: function() {
        this._super();

        const path = this.path;
        const splittedPath = path.split('.');
        splittedPath[splittedPath.length - 1] = 'override';

        const overridePath = splittedPath.join('.');
        editor.watch(overridePath, () => {
          const editorEntity = editor.getEditor(overridePath);
          if (editorEntity && this.container) {
            const override = editorEntity.value;
            this.container.style.display = override ? '' : 'none';
          }
        });

        const TEMPLATE_PATH = 'root.mail_template';

        editor.watch(TEMPLATE_PATH, () => {
          const templateSlug = editor.getEditor(TEMPLATE_PATH).getValue();
          const currentTemplate = self.pressMailTemplates.templatesContent.find(
            mailTemplate => mailTemplate.slug === templateSlug
          );

          const globalStyle =
            currentTemplate &&
            currentTemplate.content &&
            currentTemplate.content.sections &&
            currentTemplate.content.sections.globalStyle;

          this.setValue(globalStyle);
        });
      }
    });
    JSONEditor.defaults.resolvers.unshift(schema => {
      if (schema.type === 'object' && schema.extension === 'customTitle') {
        return 'customTitle';
      }
    });

    JSONEditor.defaults.editors.customDate = JSONEditor.defaults.editors.string.extend({
      build: function() {
        this._super();
        var self = this;
        self.flatpickrOptions = Object.assign(
          {
            dateFormat: 'd/m/Y',
            defaultDate: new Date()
          },
          self.schema.flatpickrOptions
        );
        window.jQuery(self.input).flatpickr(self.flatpickrOptions);
      }
    });

    JSONEditor.defaults.resolvers.unshift(schema => {
      if (schema.type === 'string' && schema.format === 'dynamicDate') {
        return 'customDate';
      }
    });

    // ---> Custom theme
    JSONEditor.defaults.themes.bootstrap3Custom = JSONEditor.AbstractTheme.extend({
      getButton: function(text, icon, title) {
        const el = this._super(text, icon, title);
        el.className += 'btn btn-primary';
        el.style.margin = '0 5px 5px 0';
        return el;
      },
      getButtonHolder: function() {
        const el = document.createElement('div');
        el.style.display = 'inline-block';
        return el;
      },
      getSwitcher: function(opt) {
        const switcher = this.getSelectInput(opt);
        switcher.style.display = 'inline-block';
        switcher.style.width = 'auto';
        return switcher;
      },
      getFormInputLabel(text) {
        const el = document.createElement('label');
        el.className += ' control-label';
        return el;
      },
      getSelectInput: function(opt) {
        const el = this._super(opt);
        el.style.marginBottom = '10px';
        el.className += 'form-control';
        return el;
      },
      setGridColumnSize: function(el, size) {
        el.className = 'col-md-' + size;
      },
      afterInputReady: function(input) {
        if (input.controlgroup) {
          return;
        }
        input.controlgroup = this.closest(input, '.form-group');
        if (this.closest(input, '.compact')) {
          input.controlgroup.style.marginBottom = 0;
        }
        if (this.queuedInputErrorText) {
          const text = this.queuedInputErrorText;
          delete this.queuedInputErrorText;
          this.addInputError(input, text);
        }
      },
      getTextareaInput: function() {
        const el = document.createElement('textarea');
        el.className = 'form-control';
        el.style.maxWidth = '100%';
        el.style.height = '100px';
        el.addEventListener('blur', function() {
          this.value = this.value.trim();
        });
        return el;
      },
      getRangeInput: function(min, max, step) {
        return this._super(min, max, step);
      },
      getFormInputField: function(type) {
        const el = this._super(type);
        el.addEventListener('blur', function() {
          this.value = this.value.trim();
        });
        if (type !== 'checkbox') {
          el.className += 'form-control';
        }
        return el;
      },
      getFormControl: function(label, input, description, infoText) {
        const group = document.createElement('div');

        group.className += ' form-group';
        if (label) {
          label.className += ' control-label';
          if (input.type === 'checkbox') {
            label.style.fontWeight = '';
          }
          group.appendChild(label);
        }

        if (infoText) {
          group.appendChild(infoText);
        }
        group.appendChild(input);

        if (description) {
          group.appendChild(description);
        }

        return group;
      },
      getIndentedPanel: function() {
        const el = document.createElement('div');
        el.className = 'well well-sm';
        el.style.paddingBottom = '0';
        return el;
      },
      getInfoButton: function(text) {
        const icon = document.createElement('span');
        icon.className = 'glyphicon glyphicon-info-sign pull-right';
        icon.style.padding = '.25rem';
        icon.style.position = 'relative';
        icon.style.display = 'inline-block';

        const tooltip = document.createElement('span');
        tooltip.style['font-family'] = 'sans-serif';
        tooltip.style.visibility = 'hidden';
        tooltip.style['background-color'] = 'rgba(50, 50, 50, .75)';
        tooltip.style.margin = '0 .25rem';
        tooltip.style.color = '#FAFAFA';
        tooltip.style.padding = '.5rem 1rem';
        tooltip.style['border-radius'] = '.25rem';
        tooltip.style.width = '25rem';
        tooltip.style.transform = 'translateX(-27rem) translateY(-.5rem)';
        tooltip.style.position = 'absolute';
        tooltip.innerText = text;
        icon.onmouseover = function() {
          tooltip.style.visibility = 'visible';
        };
        icon.onmouseleave = function() {
          tooltip.style.visibility = 'hidden';
        };

        icon.appendChild(tooltip);

        return icon;
      },
      getFormInputDescription: function(text) {
        const el = document.createElement('p');
        el.className = 'help-block';
        el.innerHTML = text;
        return el;
      },
      getHeaderButtonHolder: function() {
        const el = this.getButtonHolder();
        el.style.marginLeft = '10px';
        return el;
      },
      getTable: function() {
        const el = document.createElement('table');
        el.className = 'table table-bordered';
        el.style.width = '100%';
        return el;
      },

      addInputError: function(input, text) {
        if (!input.controlgroup) {
          this.queuedInputErrorText = text;
          return;
        }
        input.controlgroup.className = input.controlgroup.className.replace(/\s?has-error/g, '');
        input.controlgroup.className += ' has-error';
        if (!input.errmsg) {
          input.errmsg = document.createElement('p');
          input.errmsg.className = 'help-block errormsg';
          input.controlgroup.appendChild(input.errmsg);
        } else {
          input.errmsg.style.display = '';
        }

        input.errmsg.textContent = text;
      },
      removeInputError: function(input) {
        if (!input.controlgroup) {
          delete this.queuedInputErrorText;
        }
        if (!input.errmsg) {
          return;
        }
        input.errmsg.style.display = 'none';
        input.controlgroup.className = input.controlgroup.className.replace(/\s?has-error/g, '');
      },
      getTabHolder: function(propertyName) {
        const pName = typeof propertyName === 'undefined' ? '' : propertyName;
        const el = document.createElement('div');
        el.innerHTML =
          "<ul class='col-md-2 nav nav-pills nav-stacked' id='" +
          pName +
          "' role='tablist'></ul>" +
          "<div class='col-md-10 tab-content well well-sm'  id='" +
          pName +
          "'></div>";
        return el;
      },
      getTopTabHolder: function(propertyName) {
        const pName = typeof propertyName === 'undefined' ? '' : propertyName;
        const el = document.createElement('div');
        el.innerHTML =
          "<ul class='nav nav-tabs' id='" +
          pName +
          "' role='tablist'></ul>" +
          "<div class='tab-content well well-sm'  id='" +
          pName +
          "'></div>";
        return el;
      },
      getTab: function(text, tabId) {
        const li = document.createElement('li');
        li.setAttribute('role', 'presentation');
        const a = document.createElement('a');
        a.setAttribute('href', '#' + tabId);
        a.appendChild(text);
        a.setAttribute('aria-controls', tabId);
        a.setAttribute('role', 'tab');
        a.setAttribute('data-toggle', 'tab');
        li.appendChild(a);
        return li;
      },
      getTopTab: function(text, tabId) {
        const li = document.createElement('li');
        li.setAttribute('role', 'presentation');
        const a = document.createElement('a');
        a.setAttribute('href', '#' + tabId);
        a.appendChild(text);
        a.setAttribute('aria-controls', tabId);
        a.setAttribute('role', 'tab');
        a.setAttribute('data-toggle', 'tab');
        li.appendChild(a);
        return li;
      },
      getTabContent: function() {
        const el = document.createElement('div');
        el.className = 'tab-pane';
        el.setAttribute('role', 'tabpanel');
        return el;
      },
      getTopTabContent: function() {
        const el = document.createElement('div');
        el.className = 'tab-pane';
        el.setAttribute('role', 'tabpanel');
        return el;
      },
      markTabActive: function(row) {
        row.tab.className = row.tab.className.replace(/\s?not-active/g, '');
        row.tab.className += ' active';
        row.container.className = row.container.className.replace(/\s?not-active/g, '');
        row.container.className += ' active';
      },
      markTabInactive: function(row) {
        row.tab.className = row.tab.className.replace(/\s?active/g, '');
        row.tab.className += ' not-active';
        row.container.className = row.container.className.replace(/\s?active/g, '');
        row.container.className += ' not-active';
      },
      getProgressBar: function() {
        const min = '0',
          max = '100',
          start = '0';

        const container = document.createElement('div');
        container.className = 'progress';

        const bar = document.createElement('div');
        bar.className = 'progress-bar';
        bar.setAttribute('role', 'progressbar');
        bar.setAttribute('aria-valuenow', start);
        bar.setAttribute('aria-valuemin', min);
        bar.setAttribute('aria-valuenax', max);
        bar.innerHTML = start + '%';
        container.appendChild(bar);

        return container;
      },
      updateProgressBar: function(progressBar, progress) {
        if (!progressBar) {
          return;
        }

        const bar = progressBar.firstChild;
        const percentage = progress + '%';
        bar.setAttribute('aria-valuenow', progress);
        bar.style.width = percentage;
        bar.innerHTML = percentage;
      },
      updateProgressBarUnknown: function(progressBar) {
        if (!progressBar) {
          return;
        }

        const bar = progressBar.firstChild;
        progressBar.className = 'progress progress-striped active';
        bar.removeAttribute('aria-valuenow');
        bar.style.width = '100%';
        bar.innerHTML = '';
      },
      getInputGroup: function(input, buttons) {
        if (!input) {
          return;
        }

        const inputGroupContainer = document.createElement('div');
        inputGroupContainer.className = 'input-group';
        inputGroupContainer.appendChild(input);

        const inputGroup = document.createElement('div');
        inputGroup.className = 'input-group-btn';
        inputGroupContainer.appendChild(inputGroup);

        for (let i = 0; i < buttons.length; i++) {
          inputGroup.appendChild(buttons[i]);
        }

        return inputGroupContainer;
      }
    });

    // ---> CMS PDF video picker
    JSONEditor.defaults.editors.pdf = JSONEditor.defaults.editors.string.extend({
      build: function() {
        this._super();
        const pdfLinkInput = this;
        this.input.setAttribute('id', 'pdfLink-' + pdfLinkInput.formname);

        const selectButton = document.createElement('button');
        selectButton.innerHTML = 'Add PDF Link';
        selectButton.setAttribute('class', 'btn btn-primary');
        // disable for readonly content type
        if (['pressReviewsDSContent'].includes(self.contentType)) {
          selectButton.setAttribute('disabled', 'true');
        }
        selectButton.style.margin = '0 5px 15px 0';
        selectButton.addEventListener('click', e => {
          e.preventDefault();
          e.stopPropagation();
          self.addPdf.emit(pdfLinkInput.formname);
        });

        const resetButton = document.createElement('button');
        resetButton.innerHTML = 'Reset Pdf';
        resetButton.setAttribute('class', 'btn btn-secondary');
        // disable for readonly content type
        if (['pressReviewsDSContent'].includes(self.contentType)) {
          resetButton.setAttribute('disabled', 'true');
        }
        resetButton.style.margin = '0 5px 15px 0';
        resetButton.addEventListener('click', e => {
          e.preventDefault();
          e.stopPropagation();
          const pdfField = editor.getEditor(pdfLinkInput.path);
          const pdfId = editor.getEditor(pdfLinkInput.path.replace('.filename', '.id'));
          if (pdfField) {
            pdfField.setValue('');
            pdfId.setValue('');
          }
        });

        this.container.appendChild(selectButton);
        this.container.appendChild(resetButton);
      }
    });
    JSONEditor.defaults.resolvers.unshift(schema => {
      if (schema.type === 'string' && schema.extension === 'cmsPdf') {
        return 'pdf';
      }
    });

    // ---> ICS video picker
    JSONEditor.defaults.editors.ics = JSONEditor.defaults.editors.string.extend({
      build: function() {
        this._super();
        const icsLinkInput = this;
        this.input.setAttribute('id', 'icsLink-' + icsLinkInput.formname);

        const selectButton = document.createElement('button');
        selectButton.innerHTML = 'Add ICS Link';
        selectButton.setAttribute('class', 'btn btn-primary');
        // disable for readonly content type
        if (['pressReviewsDSContent'].includes(self.contentType)) {
          selectButton.setAttribute('disabled', 'true');
        }
        selectButton.style.margin = '0 5px 15px 0';
        selectButton.addEventListener('click', e => {
          e.preventDefault();
          e.stopPropagation();
          self.addIcs.emit(icsLinkInput.formname);
        });

        const resetButton = document.createElement('button');
        resetButton.innerHTML = 'Reset ICS';
        resetButton.setAttribute('class', 'btn btn-secondary');
        // disable for readonly content type
        if (['pressReviewsDSContent'].includes(self.contentType)) {
          resetButton.setAttribute('disabled', 'true');
        }
        resetButton.style.margin = '0 5px 15px 0';
        resetButton.addEventListener('click', e => {
          e.preventDefault();
          e.stopPropagation();
          const icsField = editor.getEditor(icsLinkInput.path);
          const icsId = editor.getEditor(icsLinkInput.path.replace('.filename', '.id'));
          if (icsField) {
            icsField.setValue('');
            icsId.setValue('');
          }
        });

        this.container.appendChild(selectButton);
        this.container.appendChild(resetButton);
      }
    });
    JSONEditor.defaults.resolvers.unshift(schema => {
      if (schema.type === 'string' && schema.extension === 'cmsIcs') {
        return 'ics';
      }
    });

    // ---> CMS video picker
    JSONEditor.defaults.editors.video = JSONEditor.defaults.editors.string.extend({
      build: function() {
        this._super();
        const videoLinkInput = this;
        this.input.setAttribute('id', 'videoLink-' + videoLinkInput.formname);

        const video = document.createElement('video');
        video.setAttribute('src', '');
        video.setAttribute('controls', 'true');
        video.setAttribute('preload', 'auto');
        video.setAttribute('id', 'video-' + videoLinkInput.formname);
        video.style.maxHeight = '200px';
        video.style.maxWidth = '200px';
        video.style.marginLeft = '5px';
        video.style.display = 'none';

        const selectButton = document.createElement('button');
        selectButton.innerHTML = 'Select Video';
        selectButton.setAttribute('class', 'btn btn-primary');
        // disable for readonly content type
        if (['pressReviewsDSContent'].includes(self.contentType)) {
          selectButton.setAttribute('disabled', 'true');
        }
        selectButton.style.margin = '0 5px 15px 0';
        selectButton.addEventListener('click', e => {
          e.preventDefault();
          e.stopPropagation();
          self.addVideo.emit(videoLinkInput.formname);
        });

        const resetButton = document.createElement('button');
        resetButton.innerHTML = 'Reset Video';
        resetButton.setAttribute('class', 'btn btn-secondary');
        resetButton.setAttribute('disabled', 'true');
        resetButton.style.margin = '0 5px 15px 0';
        resetButton.addEventListener('click', e => {
          e.preventDefault();
          e.stopPropagation();
          const imageField = editor.getEditor(videoLinkInput.path);
          if (imageField) {
            imageField.setValue('');
            video.setAttribute('src', '');
            video.style.display = 'none';
          }
        });

        editor.watch(videoLinkInput.path, () => {
          if (editor.getEditor(videoLinkInput.path) && editor.getEditor(videoLinkInput.path).value) {
            const videoFilename = editor.getEditor(videoLinkInput.path.replace('originalFileName', 'fileName')).value;
            if (videoFilename !== '') {
              const src = `${env.environment.mediaUrl}${videoFilename}`;
              video.setAttribute('src', src);
              video.style.display = 'block';
              // disable for readonly content type
              if (!['pressReviewsDSContent'].includes(self.contentType)) {
                resetButton.removeAttribute('disabled');
              }
            } else {
              resetButton.setAttribute('disabled', 'true');
            }
          }
        });

        const mainDiv = document.createElement('div');
        mainDiv.setAttribute('class', 'form-group');
        mainDiv.setAttribute('style', 'display: flex');
        mainDiv.appendChild(selectButton);
        mainDiv.appendChild(resetButton);
        mainDiv.appendChild(video);

        this.container.appendChild(mainDiv);
      }
    });
    JSONEditor.defaults.resolvers.unshift(schema => {
      if (schema.type === 'string' && schema.extension === 'cmsVideo') {
        return 'video';
      }
    });

    // ---> Extension only for hiding the title in the press content editor...
    JSONEditor.defaults.editors.pressContent = JSONEditor.defaults.editors.object.extend({
      postBuild: function() {
        this.title.setAttribute('style', 'display: none');
        this._super();
      }
    });
    JSONEditor.defaults.resolvers.unshift(schema => {
      if (schema.type === 'object' && schema.extension === 'pressContent') {
        return 'pressContent';
      }
    });

    // ---> Mail template based on content type
    // ---> Display or hide blocks based on content type (i.e. templateType)
    JSONEditor.defaults.editors.dsFeed = JSONEditor.defaults.editors.object.extend({
      postBuild: function() {
        this._super();

        const TEMPLATE_PATH = 'root.templateType';
        const templateTypeEditor = editor.getEditor(TEMPLATE_PATH);
        editor.watch(TEMPLATE_PATH, () => {
          const templateType = templateTypeEditor.getValue();
          const isDSFeed = templateType === 'pressReviewsDSContent';
          this.container.style.display = isDSFeed ? '' : 'none';
        });
      }
    });
    JSONEditor.defaults.resolvers.unshift(schema => {
      if (schema.extension === 'dsFeed') {
        return 'dsFeed';
      }
    });
    JSONEditor.defaults.editors.pressContentType = JSONEditor.defaults.editors.object.extend({
      postBuild: function() {
        this._super();

        const TEMPLATE_PATH = 'root.templateType';
        const templateTypeEditor = editor.getEditor(TEMPLATE_PATH);
        editor.watch(TEMPLATE_PATH, () => {
          const templateType = templateTypeEditor.getValue();
          const isPressContent = templateType !== 'pressReviewsDSContent';
          this.container.style.display = isPressContent ? '' : 'none';
        });
      }
    });
    JSONEditor.defaults.resolvers.unshift(schema => {
      if (schema.extension === 'pressContentType') {
        return 'pressContentType';
      }
    });

    // ---> Mail template sortable components
    // ---> When generating the HTML, components are sorted based on this
    JSONEditor.defaults.editors.sortableComponent = JSONEditor.defaults.editors.pressContentType.extend({
      build: function() {
        this._super();
        const mainDiv = document.createElement('div');
        const indexContent = document.createElement('span');
        indexContent.setAttribute('id', this.key);
        mainDiv.setAttribute('class', 'sortable-index');
        mainDiv.appendChild(indexContent);
        const title = this.container.getElementsByTagName('h3')[0];
        title.prepend(mainDiv);
        this.indexContent = indexContent;
      },
      postBuild: function(e) {
        this._super();
        editor.watch(this.path, () => {
          const editorEntity = editor.getEditor(this.path);
          const sortValueHasValue = typeof editorEntity.value.sortValue === 'number';
          this.indexContent.textContent = sortValueHasValue ? editorEntity.value.sortValue : '';
          if (!editorEntity.value.visible) {
            this.indexContent.textContent = '';
            editorEntity.value.sortValue = undefined;
          }
        });
      }
    });
    JSONEditor.defaults.resolvers.unshift(schema => {
      if (schema.extension === 'sortable') {
        return 'sortableComponent';
      }
    });
    // ---> In the DEM editor, show or hide the component based on the mail template definition
    JSONEditor.defaults.editors.block = JSONEditor.defaults.editors.object.extend({
      postBuild: function() {
        this._super();

        const TEMPLATE_PATH = 'root.mail_template';
        editor.watch(TEMPLATE_PATH, () => {
          const templateSlug = editor.getEditor(TEMPLATE_PATH).getValue();
          const currentTemplate = self.pressMailTemplates.templatesContent.find(
            mailTemplate => mailTemplate.slug === templateSlug
          );
          const templateBlock = currentTemplate && currentTemplate.content && currentTemplate.content[this.key];
          if (templateBlock) {
            this.container.style.display = templateBlock.visible ? '' : 'none';
          }
        });
      }
    });
    JSONEditor.defaults.resolvers.unshift(schema => {
      if (schema.type === 'object' && schema.extension === 'block') {
        return 'block';
      }
    });

    // ---> Calendar newsletter component
    JSONEditor.defaults.editors.calendar = JSONEditor.defaults.editors.block.extend({
      _syncDateAndEvents: function() {
        const dates = this._dateEditor.getValue();
        const dateArray = !dates ? [] : dates.split(', ');

        const dateTabsValue = this._dateTabsEditor.getValue();
        this._dateTabsEditor.setValue(
          dateArray.map(date => ({
            ...(dateTabsValue.find(event => event.date === date) || {}),
            date
          }))
        );
      },
      postBuild: function() {
        this._super();

        const calendarDatePath = 'root.calendar.date';
        const dateTabs = 'root.calendar.content';

        this._dateEditor = editor.getEditor(calendarDatePath);
        this._dateTabsEditor = editor.getEditor(dateTabs);

        const self = this;

        editor.watch(calendarDatePath, () => {
          self._syncDateAndEvents.call(self);
        });
      }
    });
    JSONEditor.defaults.resolvers.unshift(schema => {
      if (schema.type === 'object' && schema.extension === 'calendar') {
        return 'calendar';
      }
    });
    // ---> Extensione related to the calendar component
    JSONEditor.defaults.editors.event = JSONEditor.defaults.editors.object.extend({
      getTitle: function() {
        if (!this.value || !this.value.date) {
          return;
        }
        const formattedDate = moment(this.value.date, 'DD/MM/YYYY').format('dddd D/M');
        const capitalized = formattedDate
          .split(' ')
          .map(word => `${word[0].toUpperCase()}${word.slice(1)}`)
          .join(' ');
        return capitalized;
      }
    });
    JSONEditor.defaults.resolvers.unshift(schema => {
      if (schema.type === 'object' && schema.format === 'event') {
        return 'event';
      }
    });
    // ---> jQuery flatpickr set for multiple dates input
    JSONEditor.defaults.editors.multipleDate = JSONEditor.defaults.editors.string.extend({
      setValue: function(value, ...args) {
        this.flatpickr.setDate(value.split(', '));
        this.value = value; // Needed to trigger calendar watch...
        this._super(value, ...args);
      },
      onChange: function(...args) {
        if (!this.flatpickr) {
          return;
        }
        const sortedDates = this.flatpickr.selectedDates.sort((dateA, dateB) =>
          moment(dateA).isBefore(moment(dateB)) ? -1 : moment(dateA).isAfter(moment(dateB)) ? 1 : 0
        );
        this.setValue(sortedDates.map(date => moment(date).format('DD/MM/YYYY')).join(', '));
        this._super(...args);
      },
      build: function() {
        this._super();
        var self = this;
        self.flatpickrOptions = Object.assign(
          {
            dateFormat: 'd/m/Y',
            mode: 'multiple',
            altInput: true,
            altFormat: 'l j/m',
            locale: Italian
          },
          self.schema.flatpickrOptions
        );
        self.flatpickr = window.jQuery(self.input).flatpickr(self.flatpickrOptions);
      }
    });
    JSONEditor.defaults.resolvers.unshift(schema => {
      if (schema.type === 'string' && schema.format === 'multipleDate') {
        return 'multipleDate';
      }
    });

    const element = document.getElementById(this.editorId);
    this.jsonEditorElement = element;
    const editor = new JSONEditor(element, options);
    this.editor = editor;

    // TODO: check endpoint to perform action
    const TEMPLATE_PATH = 'root.mail_template';
    editor.watch(TEMPLATE_PATH, () => {
      const templateEditor = editor.getEditor(TEMPLATE_PATH);
      if (templateEditor) {
        const templateSlug = templateEditor.getValue();
        const template = mailTemplates.templatesContent.find(mailTemplate => mailTemplate.slug === templateSlug);
        this.templateChange.emit(template);
      }
    });

    editor.on('ready', () => {
      if (values !== null && values !== 'undefined') {
        editor.setValue(values);
      }
      editor.validate();
      // disable for readonly content type
      if (
        'pressReviewsDSContent' === this.contentType ||
        (!this.editorAlwaysEnabled && !this.canEditAfterApproval && (this.contentPending || this.contentApproved))
      ) {
        this.editor.disable();
      }

      if (this.contentType === 'mailTemplatesContent') {
        const sectionPath = 'root.sections';
        const imageWithLinkPath = 'root.image_with_link';

        editor.watch(sectionPath, () => {
          const sections = editor.getEditor(sectionPath).getValue();
          const imageWithLinkEditor = editor.getEditor(imageWithLinkPath);
          if (sections && sections.visible) {
            imageWithLinkEditor.setValue({ visible: false });
          }
        });

        editor.watch(imageWithLinkPath, () => {
          const imageWithLink = editor.getEditor(imageWithLinkPath).getValue();
          const sectionEditor = editor.getEditor(sectionPath);
          if (imageWithLink && imageWithLink.visible) {
            sectionEditor.setValue({ ...sectionEditor.getValue(), visible: false });
          }
        });

        const updateCollapseStatus = childEditor => {
          const { visible } = editor.getEditor(childEditor.options.path).getValue();
          if (typeof visible === 'boolean') {
            const expandBtn = childEditor.toggle_button;
            childEditor.collapsed = !visible;
            if (childEditor.editor_holder) {
              if (visible) {
                childEditor.editor_holder.style.display = '';
                childEditor.setButtonText(expandBtn, '', 'collapse', 'Collapse');
              } else {
                childEditor.editor_holder.style.display = 'none';
                childEditor.setButtonText(expandBtn, '', 'expand', 'Expand');
              }
            }
          }
        };
        Object.values(editor.editors).forEach((childEditor: any) => {
          if (childEditor.parent && childEditor.parent.key === 'root') {
            updateCollapseStatus(childEditor);
            editor.watch(childEditor.options.path, updateCollapseStatus.bind(null, childEditor));
          }
        });
      }
    });

    editor.on('change', () => {
      editor.validate();
      this.checkChanges(editor.getValue());
    });

    this.editorObj.emit(this.editor);
  }

  checkChanges(content) {
    this.tempValues = content;
    this.valuesToSave.emit(content);
  }

  ngOnDestroy() {
    this.editor.unwatch();
    this.editor.off();
    this.editor.destroy();
    // Remove all EventListners
    this.jsonEditorElement.remove();
  }
}
