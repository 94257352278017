import { NgModule } from '@angular/core';
import { CommonApi } from './apis/common.api';
import { MediaApi } from './apis/media.api';
import { LogsApi } from './apis/logs.api';
import { ContentManagementApi } from './apis/content-management-api.service';
import { MenuApi } from './apis/menu.api';
import { DataStampaFeedApi } from './apis/datastampafeed.api';
import { AdnkronosFeedApi } from './apis/adnkronosfeed.api';
import { NotificationsApi } from './apis/notifications.api';
import { ApprovalFlowApi } from './apis/approval-flow.api';
import { FeedApi } from './apis/feed.api';

@NgModule({
  declarations: [],
  imports: [],
  providers: [CommonApi, ContentManagementApi, MediaApi, MenuApi, FeedApi, DataStampaFeedApi, AdnkronosFeedApi, NotificationsApi, LogsApi, ApprovalFlowApi],
  exports: []
})
export class ApiModule { }
