import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { socialIcons, calendarIcons } from './icon.map';
import { DataStampaGeneratorService } from './data-stampa-generator.service';
import { AdnkGeneratorService } from './adnk-generator.service';

const env = require('src/environments/environment');

@Injectable()
export class BlockGeneratorService {
  constructor(protected dsGeneratorService: DataStampaGeneratorService, protected adnkGeneratorService: AdnkGeneratorService) { }

  private readonly VIDEO_CONTENT = 'VIDEO';
  private readonly PDF_CONTENT = 'PDF';
  private readonly CALENDAR_CONTENT = 'CALENDAR';

  private getContentLink = block => {
    if (typeof block.link === 'string') {
      return block.link;
    } else if (block.link.type === this.PDF_CONTENT) {
      return `${env.environment.mediaUrl}${block.link.fileName}`;
    } else if (block.link.type === this.VIDEO_CONTENT) {
      return `${env.environment.mediaUrl}${block.link.fileName}`;
    } else if (block.link.type === this.CALENDAR_CONTENT) {
      return `${env.environment.mediaUrl}${block.link.fileName}`;
    } else {
      return block.link.url;
    }
  };

  private generalWidth;

  generateMail(mailTemplate, content, locale, comunicationId, endpoint): string {
    if (mailTemplate.content.templateType === 'pressReviewsDSContent') {
      return this.dsGeneratorService.generateMail(mailTemplate, content);
    }
    if (mailTemplate.content.templateType === 'pressReviewsAdnkContent') {
      return this.adnkGeneratorService.generateMail(mailTemplate, content);
    }

    const {
      header,
      dynamic_date,
      pre_title,
      main_title,
      main_image,
      main_image_link,
      articles,
      primes,
      article_text,
      article_image,
      optionalSection,
      images,
      videos,
      article_url,
      article_url_link,
      article_pdf,
      article_pdf_en,
      image_with_link,
      sections_global_title,
      sections,
      section_separator,
      social_section,
      footer,
      link_press_complete,
      sent_time,
      sent_minutes,
      documents,
      calendar
    } = content;

    this.generalWidth = mailTemplate.content.general_settings
      ? mailTemplate.content.general_settings.general_width
      : 600;

    let mailHTML = '';

    let contentsSorted = [];
    const headerTemplate = mailTemplate.content.header_template.template;
    const strippedHeaderTemplate = headerTemplate && headerTemplate.replace(/(<([^>]+)>)/gi, '');
    const footerTemplate = mailTemplate.content.footer_template.template;
    const strippedFooterTemplate = footerTemplate && footerTemplate.replace(/(<([^>]+)>)/gi, '');

    const hasImageHeader = mailTemplate.content.header.visible && header && header.showHeader;
    const hasImageFooter = mailTemplate.content.footer.visible && footer && footer.showFooter;

    const customImageHeader = hasImageHeader && header.customHeader && header.customHeader.id;
    const customImageFooter = hasImageFooter && footer.customFooter && footer.customFooter.id;

    if (strippedHeaderTemplate && !customImageHeader) {
      mailHTML += this.generateFromTemplate(
        headerTemplate,
        locale,
        content,
        sent_time,
        mailTemplate.content.sent_time.visible,
        sent_minutes
      );
    } else if (hasImageHeader) {
      // Render the header image block only if the template is disabled or, in case it is enabled, if there is a custom image
      const defaultHeader = header.headerDefault && header.headerDefault.header;
      const headerImageID = header.id || customImageHeader || defaultHeader;

      mailHTML += this.generateHeader({ id: headerImageID, link: this.getContentLink(header) });
    }

    if (mailTemplate.content.dynamic_date.visible && dynamic_date) {
      mailHTML += this.generateDynamicDate(dynamic_date);
    }

    if (pre_title) {
      mailHTML += this.generatePreTitle(pre_title);
    }

    if (main_title) {
      mailHTML += this.generateMainTitle(main_title);
    }

    if (main_image && main_image_link) {
      mailHTML += this.generateImageWithLink({ ...main_image, ...main_image_link });
    }

    //FROM HERE SORT CONTENT
    if (mailTemplate.content.image_with_link.visible && image_with_link && image_with_link.id) {
      const contentLink = this.getContentLink(image_with_link);

      const HTMLContent = this.generateImageWithLink({
        id: image_with_link.id,
        link: contentLink,
        width: image_with_link.width,
        alt: image_with_link.alt
      });
      contentsSorted.push({
        content: HTMLContent,
        sortValue: mailTemplate.content.image_with_link.sortValue,
        section: 'IMAGE WITH LINK'
      });
    } else if (mailTemplate.content.sections.visible && sections) {
      const globalStyle = mailTemplate.content.sections && mailTemplate.content.sections.globalStyle;
      let HTMLContent = this.generateSectionsGlobalTitle(
        mailTemplate.content.sections_global_title,
        sections_global_title
      );
      HTMLContent += this.generateSections(sections.content, section_separator, globalStyle);
      contentsSorted.push({
        content: HTMLContent,
        sortValue: mailTemplate.content.sections.sortValue,
        section: 'SECTIONS'
      });
    }

    if (articles && articles.length) {
      const HTMLContent = this.generateArticles(articles);
      contentsSorted.push({
        content: HTMLContent,
        section: 'ARTICLES'
      });
    }

    if (article_text) {
      const HTMLContent = this.generateArticleText(article_text);
      contentsSorted.push({ content: HTMLContent });
    }

    if (mailTemplate.content.images.visible && images && images.images_list && images.images_list.length) {
      const HTMLContent = this.generateImagesUpdate(images, comunicationId, endpoint);
      contentsSorted.push({
        content: HTMLContent,
        sortValue: mailTemplate.content.images.sortValue,
        section: 'IMAGES'
      });
    }

    if (mailTemplate.content.videos.visible && videos && videos.videos_list && videos.videos_list.length) {
      const HTMLContent = this.generateVideosUpdate(videos, comunicationId, endpoint);
      contentsSorted.push({ content: HTMLContent, sortValue: mailTemplate.content.videos.sortValue });
    }

    const articleButtons = article_url || article_url_link || article_pdf || article_pdf_en;
    if (articleButtons) {
      const HTMLContent = this.generateArticleButtons(article_url, article_url_link, article_pdf, article_pdf_en);
      contentsSorted.push({
        content: HTMLContent,
        section: 'ARTICLES'
      });
    }

    if (article_image) {
      const HTMLContent = this.generateImage(article_image);
      contentsSorted.push({
        content: HTMLContent,
        section: 'ARTICLES'
      });
    }

    if (mailTemplate.content.sections.visible && optionalSection && optionalSection.article_text) {
      const HTMLContent = this.generateArticleText(article_text);
      contentsSorted.push({
        content: HTMLContent,
        sortValue: mailTemplate.content.sections.sortValue,
        section: 'SECTIONS'
      });
    }

    if (mailTemplate.content.sections.visible && optionalSection && optionalSection.article_image) {
      const HTMLContent = this.generateImage(optionalSection.article_image);
      contentsSorted.push({ content: HTMLContent, sortValue: mailTemplate.content.sections.sortValue });
    }

    if (mailTemplate.content.primes.visible && primes) {
      const HTMLContent = this.generatePrimes(primes.content || primes);
      contentsSorted.push({ content: HTMLContent, sortValue: mailTemplate.content.primes.sortValue });
    }

    if (mailTemplate.content.social_section.visible && social_section) {
      const HTMLContent = this.generateSocialSection(social_section);
      contentsSorted.push({ content: HTMLContent, sortValue: mailTemplate.content.social_section.sortValue });
    }
    if (mailTemplate.content.documents.visible && documents) {
      const HTMLContent = this.generateDocuments(documents);
      contentsSorted.push({
        content: HTMLContent,
        sortValue: mailTemplate.content.documents.sortValue,
        section: 'DOCUMENTS'
      });
    }
    if (
      mailTemplate.content.link_press_complete &&
      mailTemplate.content.link_press_complete.visible &&
      mailTemplate.content.link_press_complete.links.length &&
      link_press_complete
    ) {
      const { send_time, send_minutes } = sent_time;
      const HTMLContent = this.generatePressLinks(
        link_press_complete.link,
        send_time,
        send_minutes,
        mailTemplate.content.sent_time.visible
      );
      contentsSorted.push({ content: HTMLContent, sortValue: mailTemplate.content.link_press_complete.sortValue });
    }

    //END SORT

    let maxNumber = 0;
    contentsSorted.forEach(element => {
      maxNumber = element.sortValue > maxNumber ? element.sortValue : maxNumber;
    });

    contentsSorted.sort((prev, next) => (prev.sortValue || maxNumber + 1) - (next.sortValue || maxNumber + 1));
    contentsSorted.forEach(content => (mailHTML += content.content));

    mailHTML += this.generateCalendar(calendar);

    if (strippedFooterTemplate && !customImageFooter) {
      mailHTML += this.generateFromTemplate(footerTemplate, locale, content);
    } else if (hasImageFooter) {
      // Render the footer image block only if the template is disabled or, in case it is enabled, if there is a custom image
      const defaultFooter = footer.footerDefault && footer.footerDefault.footer;
      const footerImageID = footer.id || customImageFooter || defaultFooter;

      const footerText = footer.text;

      mailHTML += this.generateFooter({ id: footerImageID, link: this.getContentLink(footer), text: footerText });
    }
    return mailHTML;
  }

  private generateFromTemplate(baseHtml = '', locale, content?, sent_time?, sent_time_visible?, sent_minutes?) {
    const send_time =
      sent_time && sent_time.send_time
        ? sent_time.send_time
        : typeof sent_time === 'string'
          ? sent_time
          : moment().format('HH');
    const send_minutes =
      sent_time && sent_time.send_minutes
        ? sent_time.send_minutes
        : typeof sent_minutes === 'string'
          ? sent_minutes
          : moment().format('mm');

    // get placeholders
    let replaceContent = `<table align="center" width=${this.generalWidth}>` + baseHtml + '</table>';
    const placeholders = baseHtml.match(/\${{(.*?)\}}/g);

    if (!placeholders) return replaceContent;

    placeholders.forEach(placeholder => {
      // clear placeholder name
      let placeholderName = placeholder.substring(3, placeholder.length - 2);

      // particular cases
      switch (placeholderName) {
        case 'send_time':
          replaceContent = replaceContent.replace(
            `\${{${placeholderName}}}`,
            sent_time_visible ? `ORE ${send_time}` : ''
          );
          break;
        case 'send_minutes':
          replaceContent = replaceContent.replace(
            `\${{${placeholderName}}}`,
            sent_time_visible && send_minutes ? `:${send_minutes}` : ''
          );
          break;
        case 'send_date':
          const event = new Date();
          const options = { year: 'numeric', month: 'long', day: 'numeric' };
          // @ts-ignore
          replaceContent = replaceContent.replace(
            `\${{${placeholderName}}}`,
            // @ts-ignore
            event.toLocaleDateString(locale, options).replace(/,/g, '')
          );
          break;
        case 'apiUrl':
          replaceContent = replaceContent.replace(`\${{${placeholderName}}}`, env.environment.apiUrl);
          break;
        case 's3Bucket':
          replaceContent = replaceContent.replace(`\${{${placeholderName}}}`, env.environment.s3Bucket);
          break;
        case 'article_pdf':
          if (content[placeholderName] && content[placeholderName].filename) {
            replaceContent = replaceContent.replace(
              '${{article_pdf}}',
              `${env.environment.mediaUrl}${content[placeholderName].filename}`
            );
          }
          break;
        default:
          // check placeholder content
          if (content[placeholderName]) {
            replaceContent = replaceContent.replace(`\${{${placeholderName}}}`, content[placeholderName]);
          }
      }
    });

    return replaceContent;
  }
  private sliceIntoChunks(arr, chunkSize) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      res.push(chunk);
    }
    return res;
  }

  private generateImagesUpdate(
    { title, weight, title_visible, images_per_row = 1, images_list },
    communicationId,
    endpoint
  ) {
    if (!images_list.length) return '';
    const chunks = this.sliceIntoChunks(images_list, images_per_row);
    return `
      <table style="table-layout: fixed; border-collapse: collapse; font-family: Arial; margin: 20px auto;" align="center" width="${this.generalWidth
      }">
        <tbody>
        ${title_visible && title
        ? `<tr><th style="text-align: start; font-weight: ${weight}; white-space: nowrap;">${title}</th></tr>`
        : ''
      }
        ${chunks
        .map(chunk => {
          return `${this.generateImagesRow(images_per_row, chunk, communicationId, endpoint)}`;
        })
        .join('')}
        </tbody>
      </table>`;
  }
  private generateImagesRow(images_per_row, images_list, communicationId, endpoint) {
    return `
    <tr>
    ${images_list
        .map(image => {
          return `${this.generateImageWithCaption(image, communicationId, endpoint, images_per_row)}`;
        })
        .join('')}
    </tr>`;
  }

  private generateImageWithCaption({ article_image }, communicationId, endpoint, images_per_row) {
    const imageWidth = this.generalWidth / images_per_row - 10;
    return `
      <td style="line-height: 25px; padding: 25 0 25 0; text-align: center;">
          <img style="width=${imageWidth}" alt="${article_image.linkName}" src="${env.environment.apiUrl}network-content/medias/resize/${article_image.id}?width=${imageWidth}" / >
          <figcaption>
            <a target="_blank" href="${env.environment.apiUrl}network-content/medias/downloadAttachment/${article_image.id}?communicationId=${communicationId}&type=${endpoint}">HI</a>
            <a target="_blank" href="${env.environment.apiUrl}network-content/medias/downloadAttachment/${article_image.id}?width=1200&communicationId=${communicationId}&type=${endpoint}">MED</a>
            <a target="_blank" href="${env.environment.apiUrl}network-content/medias/downloadAttachment/${article_image.id}?width=600&communicationId=${communicationId}&type=${endpoint}">LOW</a>
          </figcaption>
      </td>`;
  }

  private generateDocuments({ title, weight, title_visible, docs_per_row = 1, documents_list }) {
    if (!documents_list.length) return '';
    const chunks = this.sliceIntoChunks(documents_list, docs_per_row);
    return `
      <table style="table-layout: fixed; border-collapse: collapse; font-family: Arial; margin: 20px auto;" align="center" width="${this.generalWidth
      }">
        <tbody>
        ${title_visible && title
        ? `<tr><th style="text-align: start; font-weight: ${weight}; white-space: nowrap;">${title}</th></tr>`
        : ''
      }
        ${chunks
        .map(chunk => {
          return `${this.generateDocumentRow(docs_per_row, chunk)}`;
        })
        .join('')}
        </tbody>
      </table>`;
  }

  generateDocumentTitle({ font, size, color, background, position, image, titleText }, colspan): string {
    const hasText = !!titleText;
    const hasImage = !!(image && image.id);
    const imagePosition = hasImage && image.position ? image.position : null;
    const iconFirst = hasImage && image.position === 'left';
    const colSpan = colspan ? colspan : hasText && hasImage ? 1 : 2;
    const titleMargin =
      imagePosition === 'right' && position === 'center'
        ? `"margin-left: ${size}px"`
        : imagePosition === 'left' && position === 'center'
          ? `"margin-right:${size}px"`
          : '';
    let backgroundSettings = background; // This could be a string
    if (background.id) {
      backgroundSettings = `url('${env.environment.apiUrl}network-content/medias/resize/${background.id}?width=600')`;
    }

    const title = `
    <td colspan="${colSpan}" style="font-family: ${font}; font-size: ${size}; color: ${color}; text-align: ${position}; padding: 10px;">
    <span style=${titleMargin}>${titleText}</span>
    </td>`;

    const icon = image.id
      ? `
    <td colspan="${colSpan}" style="width: ${size}; padding: 10px">
      <img width="${size}" src="${env.environment.apiUrl}network-content/medias/resize/${image.id}?width=300" />
    </td>
    `
      : '';

    return `
    <tr style="background: ${backgroundSettings}">
      ${!hasImage ? `${title}` : iconFirst ? `${icon}${title}` : `${title}${icon}`}
    </tr>
    `;
  }

  private generateDocumentRow(docs_per_row, documents_list) {
    return `
    <tr>
    ${documents_list
        .map(document => {
          if (document.link.type === 'text') {
            return this.generateDocumentTextLink(document.link, document.id, docs_per_row); // TODO: Check (filename?)
          } else if (document.link.type === 'image') {
            return this.generateDocumentImageLink(document.link, document.id, docs_per_row); // TODO: Check (filename?)
          } else return '';
        })
        .join('')}
    </tr>`;
  }

  private generateDocumentImageLink({ fileName, id }, pdfId, docsPerRow) {
    const imageWidth = this.generalWidth / docsPerRow - 10;
    if (!pdfId || !id) return '<td>Invalid document</td>';
    return `
      <td style="line-height: 25px; padding: 25 0 25 0; text-align: center;">
        <a style="color: white; text-decoration: none; text-transform: uppercase;" href="${env.environment.mediaUrl}${pdfId}" target="_blank">
        <img style="width=${imageWidth}" src="${env.environment.apiUrl}network-content/medias/resize/${id}?width=${imageWidth}" />
        </a>
      </td>`;
  }
  private generateDocumentTextLink(
    { link_background_color, link_color, link_title, link_weight = 200 },
    pdfId,
    docsPerRow
  ) {
    return `
      <td style="line-height: 25px; padding: 25 0 25 0; text-align: center;">
        <a style="color: ${link_color};background-color: ${link_background_color}; font-weight: ${link_weight}; text-decoration: none; box-sizing: border-box; border: 10px solid ${link_background_color}; text-transform: uppercase;" href="${env.environment.mediaUrl
      }${pdfId}" target="_blank">
          ${link_title || 'Titolo documento'}
        </a>
      </td>`;
  }

  private generateHeader({ id, link }): string {
    return id
      ? `
    <table style="border-collapse: collapse" align="center" width="${this.generalWidth}">
      <tbody>
        <tr>
          <td style="line-height: 25px; padding: 25 0 25 0">
            ${link
        ? `
            <a href="${link}">
              <img width="${this.generalWidth}" src="${env.environment.apiUrl}network-content/medias/resize/${id}?width=${this.generalWidth}" />
            </a>
            `
        : `<img width="${this.generalWidth}" src="${env.environment.apiUrl}network-content/medias/resize/${id}?width=${this.generalWidth}" />`
      }
          </td>
        </tr>
      </tbody>
    </table>
    `
      : '';
  }

  private generateDynamicDate({ date, font, color, size, position, weight }): string {
    const renderDate = date ? date : new Date().toLocaleDateString();
    return `
    <table style="border-collapse: collapse" align="center" width="${this.generalWidth}">
      <tbody>
        <tr>
          <td style="line-height: 25px; padding: 25 0 25 0; text-align: ${position}; font-weight: ${weight};">
            <span style="font-family: ${font}; color: ${color}; font-size: ${size};">${renderDate}</span>
          </td>
        </tr>
      </tbody>
    </table>
    `;
  }

  private generateImageWithLink(
    { id, link, alt, width = this.generalWidth },
    padding_left = 0,
    padding_right = 0,
    alignment = 'center'
  ) {
    const imageWidth = width || this.generalWidth - (padding_left + padding_right);
    return id
      ? `
    <table align="${alignment}" width="${imageWidth}">
      <tbody>
        <tr>
          <td style="padding: 0 ${padding_right} 0 ${padding_left}; text-align: ${alignment};">
            <a href="${link}" target="_blank">
              <img alt="${alt}" width="${imageWidth}" src="${env.environment.apiUrl}network-content/medias/resize/${id}?width=${imageWidth}" />
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    `
      : '';
  }

  private generateSectionSeparator({ text, color, image }): string {
    const hasText = !!text;
    const hasImage = !!(image && image.id);

    const colSpan = hasText && hasImage ? 1 : 2;
    const imageWidth = text ? this.generalWidth / 2 - 20 : this.generalWidth - 20;
    // If there is only one element of text, image, then span its column to the table column number
    // Each element (ie row) in this layout (tables) has at most 2 columns

    return `<tr style="background: ${color}">
    ${text
        ? `
      <td style="padding: 10px" colspan="${colSpan}">
        ${text}
      </td>
    `
        : ''
      }
    ${image && image.id
        ? `
      <td style="padding: 10px" colspan="${colSpan}">
        <img width="${imageWidth}" src="${env.environment.apiUrl}network-content/medias/resize/${image.id}?width=${imageWidth}" />
      </td>
    `
        : ''
      }
    </tr>`;
  }

  private generateSectionTitle(
    { font, size, weight = 200, color, background, position, image, titleText, image_height, title_height },
    colspanDefault
  ): string {
    const hasText = !!titleText;
    const hasImage = !!(image && image.id);
    const imagePosition = hasImage && image.position ? image.position : null;
    const iconFirst = hasImage && image.position === 'left';
    const colSpan = colspanDefault ? colspanDefault : hasText && hasImage ? 1 : 2;
    const titleMargin =
      imagePosition === 'right' && position === 'center'
        ? `margin-left: ${size}px`
        : imagePosition === 'left' && position === 'center'
          ? `margin-right:${size}px`
          : '';
    let backgroundSettings = background; // This could be a string
    if (background.id) {
      backgroundSettings = `url('${env.environment.apiUrl}network-content/medias/resize/${background.id}?width=600')`;
    }
    if (background.color) {
      backgroundSettings = background.color;
    }

    const title = titleText
      ? `
    <td colspan="${colSpan}" style="font-weight: ${weight}; font-family: ${font}; font-size: ${size}px; color: ${color}; text-align: ${position}; padding: 10px;">
    <span style="${titleMargin};">${titleText}</span>
    </td>`
      : '';

    const icon = image.id
      ? `
    <td colspan="${colSpan}" style="width: ${image.image_height || size}; padding: 10px">
      <img width="${image.image_height || size}" src="${env.environment.apiUrl}network-content/medias/resize/${image.id
      }?width=${image.image_height || size}" />
    </td>
    `
      : '';

    return `
    <tr style="background: ${backgroundSettings}; height: ${title_height};padding: 0;">
      ${!hasImage ? `${title}` : iconFirst ? `${icon}${title}` : `${title}${icon}`}
    </tr>
    `;
  }
  private generateSectionsGlobalTitle(defaultStyle, style): string {
    const { globalStyle, visible: defaultVisible } = defaultStyle;
    const { override, text, customTitle, image, padding_left, padding_right, position, visible: customVisible } = style;

    if (!defaultVisible || !customVisible || !text) return '';
    const overrideStyle = override ? customTitle : globalStyle;
    let imageContent = '';

    if (image.id && image.fileName) {
      const link = this.getContentLink(image);
      if (link) {
        imageContent = this.generateImageWithLink(
          {
            id: image.id,
            link,
            width: image.width,
            alt: image.alt
          },
          padding_left,
          padding_right
        );
      } else {
        imageContent = this.generateImage({ id: image.id, width: image.width }, padding_left, padding_right);
      }
    }

    return `
    ${position == 0 ? imageContent : ''}
    <table style="border-collapse: collapse; table-layout: fixed;" align="center" width="${this.generalWidth}">
      <tbody>
        ${this.generateSectionTitle({ ...overrideStyle, titleText: text, image: {} }, null)}
      </tbody>
    </table>
    ${position == 1 ? imageContent : ''}
    `;
  }

  private generateSections(sections, sectionSeparator, globalStyle): string {
    return `
      ${sections
        .map(({ title, body, ...sectionPadding }) => {
          const newWidth =
            this.generalWidth - ((sectionPadding.padding_left || 0) + (sectionPadding.padding_right || 0));
          const { padding_left = 0, padding_right = 0, padding_top = 0, padding_bottom = 0, alignment } = body;
          const { override, visible } = title;
          // 0 is before the image, 1 is after the image
          const bodyOrder = body.position;
          const titleText = title.text;
          const sectionStyle = override ? title.customTitle : globalStyle;
          const bodyImages = `
          <tr>
              <td colspan="2">
              ${body.images.length
              ? body.images
                .map(image => {
                  if (image.link && image.link.type === 'string') {
                    return this.generateImage(
                      { id: image.id, width: image.width },
                      padding_left,
                      padding_right,
                      alignment
                    );
                  } else if (image.link && (image.link.type || image.link.url)) {
                    return this.generateImageWithLink(
                      {
                        id: image.id,
                        link: this.getContentLink(image),
                        width: image.width,
                        alt: image.alt
                      },
                      padding_left,
                      padding_right,
                      alignment
                    );
                  } else {
                    return '';
                  }
                })
                .join('')
              : ''
            }
            </td>
          </tr>
        `;
          const bodyText = `
          <table style="table-layout: fixed; margin: ${sectionPadding.padding_top ||
            0} auto ${sectionPadding.padding_bottom || 0} auto" align="center" width="${newWidth}">
          <tbody>
          ${visible
              ? `<tr><td colspan="2"><table style="border-collapse: collapse; table-layout: fixed;" align="center" width="${this.generalWidth
              }">
                <tbody>
                  ${this.generateSectionTitle({ ...sectionStyle, titleText }, null)}
                </tbody>
              </table></td></tr>`
              : ''
            }
          ${!Number.parseInt(bodyOrder) ? bodyImages : ''}
            <tr>
              <td colspan="2" style="line-height: 25px; padding: ${padding_top} ${padding_right} ${padding_bottom} ${padding_left}">
                ${body.content}
              </td>
            </tr>
            ${Number.parseInt(bodyOrder) ? bodyImages : ''}
            ${sections.length > 1 && sectionSeparator.visible ? this.generateSectionSeparator(sectionSeparator) : ''}
            </tbody>
            </table>
          `;
          return bodyText;
        })
        .join('')}
     `;
  }

  private generateSocialSection({ socials }): string {
    return `
    <table style="border-collapse: collapse" align="center" width="${this.generalWidth}">
      <tbody>
        <tr>
        ${Object.entries(socials)
        .map(
          ([key, value]: any[]) => `
          ${value.visible
              ? `
          <td style="line-height: 25px; padding: 25 0 25 0; text-align: center">
            ${value.link
                ? `
            <a href="${value.link}">
              <img src="${socialIcons[key]}"/>
            </a>
            `
                : `<img src="${socialIcons[key]}"/>`
              }
          </td>
          `
              : ''
            }
        `
        )
        .join('')}
        </tr>
      </tbody>
    </table>`;
  }

  private generateFooter({ id, text, link }): string {
    // E-Phors content has footer with link and text
    // The other content has image with optional link
    // So if there is no image and there is no text, then do not render the footer
    if (!text && !id) {
      return '';
    }

    let cellContent = '';

    if (text) {
      cellContent = link ? `<a href="${link}">${text || link}</a>` : text;
    }

    if (id) {
      cellContent = link
        ? `
        <a href="${link}">
          <img width="${this.generalWidth}" src="${env.environment.apiUrl}network-content/medias/resize/${id}?width=${this.generalWidth}" />
        </a>
        `
        : `<img width="${this.generalWidth}" src="${env.environment.apiUrl}network-content/medias/resize/${id}?width=${this.generalWidth}" />`;
    }

    return `
    <table style="border-collapse: collapse" align="center" width="${this.generalWidth}">
      <tbody>
        <tr>
          <td style="line-height: 25px; padding: 25 0 25 0; text-align: center">
            ${cellContent}
          </td>
        </tr>
      </tbody>
    </table>
    `;
  }

  // Legacy from placeholder
  private generateImage(
    { id, width },
    padding_left = 0,
    padding_right = 0,
    alignment = 'center',
    newWidth = this.generalWidth
  ) {
    const imageWidth = width || newWidth - (padding_left + padding_right);
    return id
      ? `
    <table width="${newWidth}" align="center">
      <tbody>
        <tr>
          <td style="padding: 0 ${padding_right} 0 ${padding_left}; text-align: ${alignment}">
            <img width="${imageWidth}" style="width: ${imageWidth}px; object-fit: contain" src="${env.environment.apiUrl}network-content/medias/resize/${id}?width=${imageWidth}" />
          </td>
        </tr>
      </tbody>
    </table>
    `
      : '';
  }
  private generatePrimes(primes) {
    let articleHTML = '';

    if (primes.length) {
      articleHTML += `<table width="${this.generalWidth}" align="center"><tbody>
        <tr><td style="color: white; background-color:#003672; padding: 10px;text-transform:uppercase; font-family: Arial; font-size:16; font-weight: bold">Prime:</td></tr>
        <tr><td style="line-height: 25px; padding: 25 0 25 0; font-family: Arial;">`;
      primes.forEach(prime => {
        articleHTML += `${prime.prime_title} ${prime.prime_text}<br />`;
      });
      articleHTML += `</td></tr> </tbody></table> `;
    }

    return articleHTML;
  }
  private generateArticles(articles) {
    let articleHTML = '';

    articles.forEach(article => {
      articleHTML += `<table width="${this.generalWidth}" align="center"><tbody>
      <tr><td style="color: white; background-color:${article.title_background_color ||
        '#00C0EB'}; padding: 10px;text-transform:uppercase; font-family: Arial; font-size:16; font-weight: bold">${article.article_title
        }</td></tr>
      </tbody></table>`;

      const imageId = article.image_with_link && article.image_with_link.id;
      const imageAlt = article.image_with_link && article.image_with_link.alt;
      const link = imageId && this.getContentLink(article.image_with_link);
      const { alignment = 'center' } = article;

      // 0 is before the image, 1 is after the image
      const bodyOrder = article.position;
      const bodyText = `<table width="${this.generalWidth}" align="center"><tbody><tr><td style="line-height: 25px; padding: 25 0 25 0">${article.article_text}</td></tr></tbody></table>`;
      let bodyImage = '';

      if (link) {
        bodyImage += `<table width="${this.generalWidth}" align="center" valign="center" >
          <tr>
            <td>CLICCA SUL LOGO DEL TRAVEL SECURITY BULLETIN PER ESSERE SEMPRE AGGIORNATO SUI TUOI VIAGGI!</td>
          </tr>
          <tr>
            <td style="text-align:${alignment}">
                <a href="${link}" target="_blank">
                  <img width="200" src="${env.environment.apiUrl}network-content/medias/resize/${imageId}?width=200" />
                </a>
            </td>
          </tr>
        </table>`;
      } else if (imageId) {
        bodyImage += `<table width="${this.generalWidth}" align="center" valign="center" >
          <tr>
            <td style="text-align:${alignment}">
                  <img alt="${imageAlt}" width="200" src="${env.environment.apiUrl}network-content/medias/resize/${imageId}?width=200" />
            </td>
          </tr>
        </table>`;
      }

      if (Number.parseInt(bodyOrder)) {
        articleHTML += bodyText;
        articleHTML += bodyImage;
      } else {
        articleHTML += bodyImage;
        articleHTML += bodyText;
      }
    });

    return articleHTML;
  }

  private generateArticleText(articleText) {
    return `
    <table width="${this.generalWidth}" align="center">
      <tbody>
        <tr>
          <td>
            <div style="line-height: 25px;">${articleText}</div>
          </td>
        </tr>
      </tbody>
    </table>
    `;
  }
  private generateArticleButtons(article_url, article_url_link, article_pdf, article_pdf_en) {
    let articleButtonsHTML = '';

    articleButtonsHTML +=
      '<table align="center" width="${this.generalWidth}" style="margin: auto; text-align: center"><tbody><tr>';
    if (article_url && article_url.length > 0) {
      const labelLink = article_url_link ? article_url_link : 'il comunicato ufficiale qui';
      const buttonLink = `<td align="left" width="300" height="40">
            <table align="center" style="margin: auto; text-align: center">
              <tbody><tr>
                <td width="300" height="30" bgcolor="#00C0EB" style="padding-top: 10; padding-bottom: 10">
                  <a style="color: white; text-decoration: none; text-transform: uppercase;" href="${article_url}" target="_blank"><strong>${labelLink}</strong></a>
                </td>
              </tr>
            </tbody></table>
          </td>`;
      articleButtonsHTML += buttonLink;
    }
    if (article_pdf_en && article_pdf_en.filename && article_pdf_en.filename.length > 0) {
      const buttonPdfEN = `<td align="left" width="300" height="40">
            <table align="center" style="margin: auto; text-align: center">
              <tbody><tr>
                <td width="300" height="30" bgcolor="#003672" style="padding-top: 10; padding-bottom: 10">
                  <a style="color: white; text-decoration: none; text-transform: uppercase;" href="${env.environment.mediaUrl
        }${article_pdf_en.id}" target="_blank"><strong>${article_pdf_en.linkName ? article_pdf_en.linkName : 'Download pdf (en)'
        }</strong></a>
                </td>
              </tr>
            </tbody></table>
          </td>`;
      articleButtonsHTML += buttonPdfEN;
    }
    if (article_pdf && article_pdf.filename && article_pdf.filename.length > 0) {
      const buttonPdf = `<td align="left" width="300" height="40">
      <table align="center" style="margin: auto; text-align: center">
        <tbody><tr>
          <td width="300" height="30" bgcolor="#003672" style="padding-top: 10; padding-bottom: 10">
            <a style="color: white; text-decoration: none; text-transform: uppercase;" href="${env.environment.mediaUrl
        }${article_pdf.id}" target="_blank"><strong>${article_pdf.linkName ? article_pdf.linkName : 'download pdf'
        }</strong></a>
          </td>
        </tr>
      </tbody></table>
    </td>`;
      articleButtonsHTML += buttonPdf;
    }
    articleButtonsHTML += '</tr></tbody></table>';

    return articleButtonsHTML;
  }

  private generateImages(images, comunicationId, endpoint) {
    if (!images.length) {
      return '';
    }

    let imagesHTML = '';
    imagesHTML += `<table width="${this.generalWidth}" align="center"><thead><tr><th style="text-align: start;">Images: </th></tr></thead><tbody>`;

    images.forEach((curr, i) => {
      if (i % 2 === 0) imagesHTML += '<tr>';
      imagesHTML += `
        <td style="text-align: center;">
          <figure>
            <img alt="${curr.article_image.linkName}" src="${env.environment.apiUrl}network-content/medias/resize/${curr.article_image.id}?width=200" / >
            <figcaption>
              <a target="_blank" href="${env.environment.apiUrl}network-content/medias/downloadAttachment/${curr.article_image.id}?communicationId=${comunicationId}&type=${endpoint}">HI</a>
              <a target="_blank" href="${env.environment.apiUrl}network-content/medias/downloadAttachment/${curr.article_image.id}?width=1200&communicationId=${comunicationId}&type=${endpoint}">MED</a>
              <a target="_blank" href="${env.environment.apiUrl}network-content/medias/downloadAttachment/${curr.article_image.id}?width=600&communicationId=${comunicationId}&type=${endpoint}">LOW</a>
            </figcaption>
          </figure>
        <td>`;
      if (i % 2 !== 0) imagesHTML += '</tr>';
    });

    imagesHTML += '</tbody></table>';

    return imagesHTML;
  }

  private generateVideos(videos, communicationId, endpoint) {
    if (!videos.length) {
      return '';
    }

    let videosHTML = '';
    videosHTML += `<table width="${this.generalWidth}" align="center"><thead><tr><th style="text-align: start;">Videos: </th></tr></thead><tbody><tr><td><ul>`;

    videos.forEach(video => { });

    videosHTML += '</ul></td></tr></tbody></table>';

    return videosHTML;
  }
  private generateVideosUpdate(
    { title, weight, title_visible, videos_per_row = 1, videos_list },
    communicationId,
    endpoint
  ) {
    if (!videos_list.length) return '';
    const chunks = this.sliceIntoChunks(videos_list, videos_per_row);
    return `
      <table style="table-layout: fixed; border-collapse: collapse; font-family: Arial; margin: 20px auto;" align="center" width="${this.generalWidth
      }">
        <tbody>
        ${title_visible && title
        ? `<tr><th style="text-align: start; font-weight: ${weight}; white-space: nowrap;">${title}</th></tr>`
        : ''
      }
        ${chunks
        .map(chunk => {
          return `${this.generateVideosRow(videos_per_row, chunk, communicationId, endpoint)}`;
        })
        .join('')}
        </tbody>
      </table>`;
  }
  private generateVideosRow(videos_per_row, videos_list, communicationId, endpoint) {
    return `
    <tr>
    ${videos_list
        .map(video => {
          return `${this.generateVideo(video, communicationId, endpoint, videos_per_row)}`;
        })
        .join('')}
    </tr>`;
  }
  private generateVideo(video, communicationId, endpoint, videos_per_row) {
    const size =
      video.article_video.size && video.article_video.size != 'undefined'
        ? '(' + (video.article_video.size / 1024 / 1024).toFixed(2) + ' MB)'
        : '';
    return `
        <td style="line-height: 25px; padding: 25 0 25 0; text-align: center;">
          <a href="${env.environment.mediaUrl}${video.article_video.fileName}">${video.article_video.linkName} ${size}</a>
        </td>`;
  }

  private generatePreTitle(preTitle) {
    return `
    <table width="${this.generalWidth}" align="center">
      <tbody>
        <tr>
          <td>
            <h3 style="font-style: italic">${preTitle}</h3>
          </td>
        </tr>
      </tbody>
    </table>
    `;
  }

  private generateMainTitle(mainTitle) {
    return `
    <table width="${this.generalWidth}" align="center">
      <tbody>
        <tr>
          <td>
            <h1 style="color: rgb(0, 54, 114)">${mainTitle}</h1>
          </td>
        </tr>
      </tbody>
    </table>
    `;
  }

  private generatePressLinks(link, time, minutes, timeVisible) {
    const timeTitle = timeVisible
      ? `ACCEDI ALL'AGGIORNAMENTO COMPLETO DELLE ${time}:${minutes}`
      : `ACCEDI ALL 'AGGIORNAMENTO COMPLETO`;
    return `
    <table
      width="${this.generalWidth}"
      align="center"
      style="
      font-family: Arial;
      font-size: 16px;
      padding: 10px;
      mso-line-height-rule: exactly;
      -ms-text-size-adjust: 100%;
      -webkit-text-size-adjust: 100%;
      background-color: #003672;
      ">
    <tbody>
      <tr>
        <td>
          <a href="https://${link}" target="_blank" style="
          font-weight: bold;
          letter-spacing: normal;
          line-height: 100%;
          text-align: center;
          text-decoration: none;
          color: #ffffff;
          mso-line-height-rule: exactly;
          -ms-text-size-adjust: 100%;
          -webkit-text-size-adjust: 100%;
          display: block;
          ">
            ${timeTitle}
          </a>
        </td>
      </tr>
    </tbody>
  </table>
  `;
  }

  private generateCalendar({ content, description } = { content: [], description: '' }) {
    const fBlue = '#003a78';
    const fWhite = '#f5f5f5';

    const width = 600;

    // If you need to change the style of this component, please be careful when changing style attributes because Outlook for Windows behaves very strangely...
    return `${description && description.replace(/(<([^>]+)>)/gi, '')
        ? `<table align="center" width="${width}" style="font-family: sans-serif; table-layout: fixed; border-collapse: collapse; margin-top: 30px; margin-bottom: 0px; padding-bottom: 0px">
    <tbody>
      <tr>
        <td style="font-weight: 300; text-align: left; padding-left: 8px; font-size: 14px; padding-top: 9px; padding-bottom: 9px">${description}</td>
        </tr>
      </tbody>
    </table>`
        : ''
      }
    ${content
        .map(
          (day, i) => `
    <table align="center" width="${width}" style="text-align: left; border: 1px solid ${fBlue}; font-family: sans-serif; table-layout: fixed; border-collapse: collapse; margin-top: 30px; margin-bottom: 0px; padding-bottom: 0px">
      <tbody style="background: ${fBlue}; text-align: left;">
        <tr style="background: ${fBlue}; text-align: left;">
          <td style="color: ${fWhite}; font-weight: 300; text-align: left; padding-left: 8px; font-size: 14px; padding-top: 9px; padding-bottom: 9px">
            ${moment(day.date, 'DD/MM/YYYY')
              .format('dddd D/M')
              .toUpperCase()}
          </td>
        </tr>
      </tbody>
    </table>
    <table align="center" width="${width}" style="font-family: sans-serif; table-layout: fixed; border-collapse: collapse; margin-top: 0px; padding-top: 0px; margin-bottom: ${i === content.length - 1 ? 30 : 0
            }px">
        ${day.events.length
              ? `<tbody>
          ${day.events
                .map(event => {
                  const descriptionDIV = document.createElement('div');
                  descriptionDIV.innerHTML = event.description;
                  Array.from(descriptionDIV.children).forEach((node: HTMLParagraphElement) => {
                    node.style.margin = '0';
                  });

                  return `
          <tr style="border: 1px solid ${fBlue}">
            <td style="width: 60px; font-size: 16px; border-left: 1px solid ${fBlue}; border-top: 1px solid ${fBlue}; border-bottom: 1px solid ${fBlue}; vertical-align: top">
              <img src=${calendarIcons[event.category]} width="42" height="56" />
              </td>
              <td style="${event.description ? 'padding-top: 14px; padding-bottom: 14px;' : ''
                    } border-top: 1px solid ${fBlue}; border-bottom: 1px solid ${fBlue}; border-right: 1px solid ${fBlue}; font-size: 16px; color: ${fBlue}"><span style="padding-top: 9px; padding-bottom: 9px"><b>${event.title
                    }</b></span>
              ${descriptionDIV.innerHTML}
              </td>
            </tr>`;
                })
                .join('')}

      </tbody>`
              : ''
            }
    </table>
    `
        )
        .join('<br />')}`;
  }
}
