import { Injectable } from '@angular/core';

@Injectable()
export class InputOptionService {
  public options = {
    general: {
      languages: [
        { label: 'English', value: 'en' },
        { label: 'Italian', value: 'it' }
      ]
    },
    mailTemplatesContent: {
      listCols: [
        { field: 'title', header: 'Title', width: '40%' },
        { field: 'roles', header: 'Roles', width: '20%' },
        { field: 'updatedAt', header: 'Date', width: '20%' },
        { field: 'actions', header: '', width: 'auto' }
      ]
    },
    pressReviewsContent: {
      listCols: [
        { field: 'title', header: 'Title', width: '60%' },
        { field: 'updatedAt', header: 'Date', width: '20%' },
        { field: 'actions', header: '', width: 'auto' }
      ]
    },
    pressReviewsDSContent: {
      listCols: [
        { field: 'title', header: 'Title', width: '60%' },
        { field: 'updatedAt', header: 'Date', width: '20%' },
        { field: 'actions', header: '', width: 'auto' }
      ]
    },
    pressReviewsAdnkContent : {
      listCols: [
        { field: 'title', header: 'Title', width: '60%' },
        { field: 'updatedAt', header: 'Date', width: '20%' },
        { field: 'actions', header: '', width: 'auto' }
      ]
    },
    pressReleasesContent: {
      listCols: [
        { field: 'title', header: 'Title', width: '60%' },
        { field: 'updatedAt', header: 'Date', width: '20%' },
        { field: 'actions', header: '', width: 'auto' }
      ]
    },
    cioCommunicationsContent: {
      listCols: [
        { field: 'title', header: 'Title', width: '60%' },
        { field: 'updatedAt', header: 'Date', width: '20%' },
        { field: 'actions', header: '', width: 'auto' }
      ]
    },
    pressCommunicationsContent: {
      listCols: [
        { field: 'title', header: 'Title', width: '60%' },
        { field: 'updatedAt', header: 'Date', width: '20%' },
        { field: 'actions', header: '', width: 'auto' }
      ]
    },
    fincantieriSIContent: {
      listCols: [
        { field: 'title', header: 'Title', width: '50%' },
        { field: 'updatedAt', header: 'Date', width: '20%' },
        { field: 'actions', header: '', width: 'auto' }
      ]
    },
    fincantieriInfrastructureContent: {
      listCols: [
        { field: 'title', header: 'Title', width: '50%' },
        { field: 'updatedAt', header: 'Date', width: '20%' },
        { field: 'actions', header: '', width: 'auto' }
      ]
    },
    opereMarittimeContent: {
      listCols: [
        { field: 'title', header: 'Title', width: '50%' },
        { field: 'updatedAt', header: 'Date', width: '20%' },
        { field: 'actions', header: '', width: 'auto' }
      ]
    },
    finsoContent: {
      listCols: [
        { field: 'title', header: 'Title', width: '50%' },
        { field: 'updatedAt', header: 'Date', width: '20%' },
        { field: 'actions', header: '', width: 'auto' }
      ]
    },
    sofContent: {
      listCols: [
        { field: 'title', header: 'Title', width: '50%' },
        { field: 'updatedAt', header: 'Date', width: '20%' },
        { field: 'actions', header: '', width: 'auto' }
      ]
    },
  };

  get(model: string, name: string) {
    if (!this.options.hasOwnProperty(model)) {
      return [];
    }
    if (!this.options[model].hasOwnProperty(name)) {
      return [];
    }
    return this.options[model][name];
  }
}
