
export const environment = {
  production: false,
  quality: false,
  envName: 'development',
  version: '0.0.0',
  build: '20240723T083829505Z',
  name: 'spindox-cms-backoffice',
  formattedName: 'CMS MEDIA',
  feBaseUrl: 'https://cms.dev.fincantieri.it',
  apiUrl: 'https://api.cms.dev.fincantieri.it/cms/',
  apiKey: 'OOPY3hEzzIIQxca6K8C9NTcuwEgJV5fv',
  s3Bucket: 'fincantieri-assets-dev',
  oauthCompleteUrl: 'https://fincantieri-cms-devtest.auth.eu-west-1.amazoncognito.com/oauth2/authorize?identity_provider=Fincatieri-Azure-AD&redirect_uri=https://cms.dev.fincantieri.it/cognito-return&response_type=token&client_id=3j8m0nf1b0pue9d7as7mvmjvn0&scope=aws.cognito.signin.user.admin email openid phone profile',
  socketUrl: 'wss://api.cms.dev.fincantieri.it',
  socketPath: '/cms/notification/websockets',
  mediaUrl: 'https://medias.cms.dev.fincantieri.it/',
};
